<template>
  <b-container
    v-if="tabIndex === 0"
    class="mb-5"
    fluid
  >
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableWallet.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group
            label="Organization"
            label-for="filter_organization"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_organization"
              v-model="tableWallet.filter.organization"
              type="text"
              size="sm"
              placeholder="no organization"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group
            label="Merchant"
            label-for="filter_merchant"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_merchant"
              v-model="tableWallet.filter.merchant_name"
              type="text"
              size="sm"
              placeholder="no merchant"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            variant="primary"
            size="sm"
            @click="onCreateWallet"
          >
            Add Wallet
          </b-button>
        </b-col>
      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableWallet"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableWallet.perPage"
            :current-page="tableWallet.currentPage"
            :items="tableWalletProvider"
            :fields="tableWallet.fields"
            :sort-by.sync="tableWallet.sortBy"
            :sort-desc.sync="tableWallet.sortDesc"
            :sort-direction="tableWallet.sortDirection"
            :filter="tableWallet.filter"
            :filter-included-fields="tableWallet.filterOn"
            :busy="tableWallet.busy"
          >
            <template #cell(index)="{ index }">
              {{ tableNumber(tableWallet, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-wrap text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(active)="{ value }">
              {{ Number(value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(amount)="{ value }">
              <div class="text-right">
                {{ value }}
              </div>
            </template>

            <template #cell(debit)="{ item }">
              <div class="text-right">
                {{ item.position === 'Debit' ? numberFormatter(item.amount) : '0.00' }}
              </div>
            </template>

            <template #cell(credit)="{ item }">
              <div class="text-right">
                {{ item.position === 'Credit' ? numberFormatter(item.amount) : '0.00' }}
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableWallet.perPage"
            :options="tableWallet.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableWallet.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableWallet.totalRows"
            :per-page="tableWallet.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-wallet"
      size="md"
      centered
      scrollable
      title="Add Wallet"
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <ValidationObserver
        ref="formWallet"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="amount"
                name="amount"
                rules="required|currency:1"
              >
                <b-form-group
                  label="Amount"
                  label-for="amount"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="amount"
                    v-model="maskedBudget.amount"
                    v-mask="currencyMask"
                    type="text"
                    class="text-right"
                    placeholder="enter amount here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <b-form-group
                  label="Remarks"
                  label-for="remarks"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-textarea
                    id="remarks"
                    v-model="wallet.remarks"
                    rows="8"
                    max-rows="12"
                    placeholder="enter remarks"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div>
            <b-button
              class="ml-1"
              variant="success"
              :disabled="state.busy"
              @click="$event => onValidateWallet($event, 'Credit')"
            >
              Submit
            </b-button>
          </div>
          <div />
          <div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminWallet } from '@/services'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'AdminMerchantWallets',

  middleware: ['auth', 'admin'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Wallets'
  }),

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    },
    merchant: {
      type: Object,
      default: () => ({
        id: null,
        first_name: null,
        last_name: null,
        organization: null,
        email: null,
        mobile_number: null,
        prefix: null,
        username: null,
        password: null,
        password_confirmation: null,
        source_account: null,
        api_rate_limit: null,
        fee: null,
        is_vip: 0,
        active: 1
      })
    }
  },

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      options: {
        positions: ['Debit', 'Credit']
      },
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: 2
      }),
      maskedBudget: {
        amount: null
      },
      wallet: {
        merchant: this.merchant.id,
        position: 'Credit',
        amount: null,
        remarks: null
      },
      tableWallet: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          organization: this.merchant.organization,
          merchant_name: `${this.merchant.first_name} ${this.merchant.last_name}`
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'amount', class: 'min-w-150px', formatter: this.numberFormatter },
          { mobile: 3, key: 'remarks', class: 'min-w-250px' },
          { mobile: 4, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {

    'maskedBudget.amount' (creditAmount) {
      const transformToNumericValue = creditAmount && creditAmount.length ? creditAmount.replace(/[^\d.]/g, '') : creditAmount
      this.wallet.amount = transformToNumericValue ? parseFloat(transformToNumericValue).toFixed(2) : null
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    async tableWalletProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableWallet.busy = true
      return await SAdminWallet.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_merchant: this.merchant.id
        })
      ).then(({ data }) => {
        this.tableWallet.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableWallet.busy = false
      })
    },

    onCreateWallet () {
      this.maskedBudget.amount = null

      this.wallet.position = 'Credit'
      this.wallet.amount = null
      this.wallet.remarks = null

      this.$bvModal.show('modal-wallet')
    },

    async onValidateWallet  (event, position) {
      event.preventDefault()
      await this.$refs.formWallet.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: '<h6>Submit Wallet?</h6>',
              confirmButtonText: 'Submit',
              customClass: {
                confirmButton: position === 'Debit' ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'
              },
              preConfirm: () => {
                this.state.busy = true
                this.wallet.position = position
                return new Promise(resolve => {
                  SAdminWallet.post(this.wallet).then(
                    ({ data: { message } }) => {
                      this.$refs.tableWallet.refresh()
                      this.maskedBudget.amount = null
                      this.wallet.position = null
                      this.wallet.amount = 0.00
                      this.wallet.remarks = null
                      this.$bvModal.hide('modal-wallet')
                      this.$refs.formWallet.reset()
                      this.swalSuccess(message)
                      resolve(message)
                    }
                  ).catch(
                    ({ message }) => {
                      this.$refs.formWallet.setErrors(message)
                      resolve(message)
                    }
                  ).finally(() => {
                    this.state.busy = false
                  })
                })
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    }
  }
}
</script>
