<template>
  <b-container
    v-if="tabIndex === 1"
    class="mb-5"
    fluid
  >
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableWebhookUrl.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Organization"
            label-for="filter_organization"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_organization"
              v-model="tableWebhookUrl.filter.organization"
              type="text"
              size="sm"
              placeholder="no organization"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Merchant"
            label-for="filter_merchant"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_merchant"
              v-model="tableWebhookUrl.filter.merchant_name"
              type="text"
              size="sm"
              placeholder="no merchant"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableWebhookUrl"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableWebhookUrl.perPage"
            :current-page="tableWebhookUrl.currentPage"
            :items="tableWebhookUrlProvider"
            :fields="tableWebhookUrl.fields"
            :sort-by.sync="tableWebhookUrl.sortBy"
            :sort-desc.sync="tableWebhookUrl.sortDesc"
            :sort-direction="tableWebhookUrl.sortDirection"
            :filter="tableWebhookUrl.filter"
            :filter-included-fields="tableWebhookUrl.filterOn"
            :busy="tableWebhookUrl.busy"
          >
            <template #cell(index)="{ index }">
              {{ tableNumber(tableWebhookUrl, index) }}
            </template>

            <template #cell()="row">
              <div class="text-wrap text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(active)="{ value }">
              {{ Number(value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableWebhookUrl.perPage"
            :options="tableWebhookUrl.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableWebhookUrl.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableWebhookUrl.totalRows"
            :per-page="tableWebhookUrl.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminWebhookUrl } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'AdminWebhookWebhookUrls',

  middleware: ['auth', 'admin'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'WebhookUrls'
  }),

  props: {
    tabIndex: {
      type: Number,
      default: () => 1
    },
    merchant: {
      type: Object,
      default: () => ({
        id: null,
        first_name: null,
        last_name: null,
        organization: null,
        email: null,
        mobile_number: null,
        prefix: null,
        username: null,
        password: null,
        password_confirmation: null,
        source_account: null,
        api_rate_limit: null,
        fee: null,
        is_vip: 0,
        active: 1
      })
    }
  },
  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      webhookUrl: {
        merchant: this.merchant.id,
        payload: null
      },
      tableWebhookUrl: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          organization: this.merchant.organization,
          merchant_name: `${this.merchant.first_name} ${this.merchant.last_name}`
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          // { mobile: 4, key: 'action', class: 'text-center min-w-150px' },
          { mobile: 1, key: 'created_at', class: 'min-w-200px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'signing_secret', class: 'min-w-200px' },
          { mobile: 3, key: 'url', class: 'min-w-350px' },
          { mobile: 4, key: 'active', class: 'text-center min-w-150px' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableWebhookUrlProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableWebhookUrl.busy = true
      return await SAdminWebhookUrl.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_merchant: this.merchant.id
        })
      ).then(({ data }) => {
        this.tableWebhookUrl.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableWebhookUrl.busy = false
      })
    }

  }
}
</script>
